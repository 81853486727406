* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Pretendard-Regular';
}

body {
  background-color: #F8F8FB;
}

/* font */
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-style: normal;
}


a {
  text-decoration: none;
}

li {
  list-style: none;
}